<script lang="ts" setup>
defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<{ modelValue: string; label?: string }>(), {
  label: 'رقم الجوال',
})

const emit = defineEmits<{
  'update:modelValue': [payload: string]
}>()
</script>

<template>
  <div>
    <label :for="$attrs.name" class="fs-14 fw-700 textPrimary mb-2 d-block">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="app-input position-relative">
      <input
        :id="$attrs.name"
        :value="modelValue"
        @input="(e) => emit('update:modelValue', e.target.value)"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>

<style lang="css" scoped>
.app-input input {
  width: 100%;
  height: 43px;
  border: 1px solid #e0e0e0;
  background: transparent;
  outline: none;
  padding: 0 15px;
  border-radius: 5px;
  margin-bottom: 0.4rem;
}
</style>
